<template>
<div class="settingprofile">
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 176px) - 50px)">
      <app-menu-profile/>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4>บัญชีธนาคาร</h4>
        </div>
      </div>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto">
        <div id="secondtime" style="display: block" v-for="item in myBankInfo" :key="item.accountID" :value="item">
          <div class="form-row" style="justify-content: center;">
            <div class="col-12">
              <label><i class="fas fa-money-check"></i> บัญชีธนาคารที่สามารถใช้ได้</label>
            </div>
            <div class="col-8 col-sm-8 col-md-4 col-lg-3 text-center">
              <input type="radio" name="bank2" id="kbank" class="input-hidden m-0" />
                <label for="kbank" class="bank-radio m-0">
                  <img
                  src="@/assets/icon/bank.png"
                  class="icon-bank"
                  style="background: white;"
                />
                <span>{{ item.bankName }}</span
                ><span class="badge badge-dark">{{ item.bankNo }}</span></label>
                <p><span class="badge badge-pill badge-secondary font-weight-light">ชื่อบัญชี</span> {{ item.bankOwner }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import menu_profile from '@/components/menu_profile'
import { mapState, mapActions } from 'vuex'
export default {
  async created () {
    await this.setLoading(true)
    await this.getMyAccountBanks()
    await this.setLoading(false)
  },
  computed: {
    ...mapState({
      myBankInfo: state => state.bankModule.myBankInfo
    })
  },
  components: {
    'app-menu-profile': menu_profile
  },
  methods: {
    ...mapActions(['getMyAccountBanks', 'setLoading'])
  }
}
</script>

<style>
</style>
